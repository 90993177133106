import React from 'react'
import * as style from "../static/styles/contact404pp.module.scss"
import Seo from "../components/seo"
import Hero from "../components/indexComponents/hero"
import Footer from "../components/footer"

const PrivacyPolicy = () => {
    return (
        <div className={style.contact404ppRoot}>
            <Seo title="Privacy Policy"/>
            <Hero/>
            <div className={style.insideWrapperPP}>
                <h1>Privacy Policy</h1>
                <p>
                Met betrekking tot de omgang met de persoonlijke gegevens van de gebruiker in de dienst die op deze site wordt aangeboden (hierna "deze dienst" genoemd), hebben we het volgende privacybeleid opgesteld (hierna "dit beleid" genoemd).
<br/>
<br/>
Artikel 1 (Persoonlijke informatie)<br/>
"Persoonlijke informatie" verwijst naar "persoonlijke informatie" zoals gedefinieerd in de wet op de bescherming van persoonlijke informatie, en is informatie over levende individuen, zoals naam, geboortedatum, adres, telefoonnummer, contactinformatie, enz. Die in de informatie is opgenomen. die een specifiek individu kan identificeren aan de hand van de beschrijving enz. en informatie die een specifiek individu kan identificeren op basis van alleen de relevante informatie, zoals uiterlijk, vingerafdruk, stemafdrukgegevens en verzekeraarnummer van het ziekteverzekeringscertificaat (persoonlijke identificatie-informatie).
<br/>
<br/>
Artikel 2 (Hoe persoonlijke informatie te verzamelen)<br/>
Als u via deze site contact met ons opneemt, kunnen we om persoonlijke informatie vragen, zoals uw naam, adres, telefoonnummer, e-mailadres, bankrekeningnummer en rijbewijsnummer.
<br/>
<br/>
Artikel 3 (doel van het verzamelen en gebruiken van persoonlijke informatie)<br/>
De doeleinden waarvoor deze site persoonlijke informatie verzamelt en gebruikt, zijn als volgt.<br/>
• Voor de levering en exploitatie van diensten<br/>
• Om vragen van gebruikers te beantwoorden (inclusief identiteitsverificatie)<br/>
• Voor onderhoud, belangrijke mededelingen enz. Om indien nodig contact met u op te nemen<br/>
• Om gebruikers te identificeren die de gebruiksvoorwaarden schenden of van plan zijn de service te gebruiken voor frauduleuze of onredelijke doeleinden, en weigeren de service te gebruiken.<br/>
• Doeleinden die verband houden met de bovengenoemde gebruiksdoeleinden<br/>
<br/>
<br/>
Artikel 4 (Wijziging gebruiksdoel)<br/>
Het gebruiksdoel van persoonlijke informatie wordt alleen gewijzigd als redelijkerwijs wordt erkend dat het gebruiksdoel verband houdt met dat van vóór de wijziging. Indien het gebruiksdoel wordt gewijzigd, zal het gewijzigde gebruiksdoel op deze site worden aangekondigd.
<br/>
<br/>
Artikel 5 (Verstrekking van persoonlijke informatie aan een derde partij)<br/>
Behalve in de volgende gevallen, zullen we geen persoonlijke informatie aan een derde partij verstrekken zonder de voorafgaande toestemming van de gebruiker. Dit is echter niet van toepassing wanneer dit is toegestaan ​​door de wet op de bescherming van persoonlijke gegevens en andere wet- en regelgeving.<br/>
• Wanneer het nodig is om het leven, het lichaam of de eigendommen van een persoon te beschermen en het moeilijk is om de toestemming van de persoon te verkrijgen.<br/>
• Wanneer het vooral nodig is om de volksgezondheid te verbeteren of de gezonde ontwikkeling van kinderen te bevorderen, en het moeilijk is om de toestemming van de persoon te krijgen.<br/>
• Wanneer het nodig is dat een nationale instelling of een plaatselijk openbaar lichaam of een persoon die ermee is belast meewerkt bij het uitvoeren van de zaken die door wet- en regelgeving worden voorgeschreven, zal het verkrijgen van de toestemming van de persoon de uitvoering van de zaken belemmeren. een risico van inspanning l<br/>
<br/>
<br/>
Artikel 6 (Persoonlijke informatie verwijderen, gebruik opschorten)<br/>
Als u het gebruik van persoonlijke informatie van de persoon via deze site wilt verwijderen of opschorten, zullen we onmiddellijk reageren nadat we hebben bevestigd dat u de persoon bent.
<br/>
<br/>
Artikel 7 (wijziging privacybeleid)<br/>
De inhoud van dit beleid kan worden gewijzigd zonder de gebruiker hiervan op de hoogte te stellen, met uitzondering van wet- en regelgeving en andere zaken die anders in dit beleid worden gespecificeerd. Het gewijzigde privacybeleid wordt van kracht vanaf het moment dat het op deze website wordt geplaatst.
                </p>
            </div>
            <Footer/>
        </div>
    )
}

export default PrivacyPolicy